.btn-group {
  .btn {
    border-radius: 0;
    margin: 0;
    border-left-width: 0;

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      border-left-width: 2px;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}
