@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-size: 14px;
    padding-top: env(safe-area-inset-top, 0);
    padding-bottom: env(safe-area-inset-bottom, 0);
    height: 100%;
    box-sizing: border-box;
}

#root {
    height: 100%;
}

body {
    @apply bg-gray-600;
    @apply text-gray-200;
    height: 100%;
}

.main-content, .main-menu {
    padding-left: env(safe-area-inset-left, 0);
    padding-right: env(safe-area-inset-right, 0);
}

th, td {
    @apply text-left;
    @apply px-3;
    @apply py-3;
    white-space: nowrap;
}

@media (min-width: 768px) {
    th, td {
        @apply py-1;
    }
}

a {
    @apply underline;
}

.material-symbols-outlined {
    font-weight: 200;
    font-size: 1.3em;
    line-height: 1.3em;
}

.text-shadow {
    text-shadow: 1px 1px 6px black;
}
